import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/BattlePass10/pic1.webp'
import Image2 from '../../images/Guide/BattlePass10/pic2.webp'
import Image3 from '../../images/Guide/BattlePass10/pic3.webp'
import Image4 from '../../images/Guide/BattlePass10/pic4.webp'
import Image5 from '../../images/Guide/BattlePass10/pic5.webp'
import Image6 from '../../images/Guide/BattlePass10/pic6.webp'
import Image7 from '../../images/Guide/BattlePass10/pic7.webp'
import Image8 from '../../images/Guide/BattlePass10/pic8.webp'
import Image9 from '../../images/Guide/BattlePass10/pic9.webp'
import Image10 from '../../images/Guide/BattlePass10/pic10.webp'
import Image11 from '../../images/Guide/BattlePass10/pic11.webp'
import Image12 from '../../images/Guide/BattlePass10/pic12.webp'
import Image13 from '../../images/Guide/BattlePass10/pic13.webp'
import Image14 from '../../images/Guide/BattlePass10/pic14.webp'
import Image15 from '../../images/Guide/BattlePass10/pic15.webp'
import { LinkContainer } from 'react-router-bootstrap';

export function BattlePass10() {
    return (
        <div className="guide white">
            <h3>Battle Pass</h3>
            
            <p className="head">About:</p>
            <ul>
                <li>This guide covers the Battle Pass #10.</li>
            </ul>

            <p className="head">NPCs, Items, Maps:</p>
            <ol>
                <li>BattlePass <i>(Item)</i></li>
                <li>EssenceChest <i>(Twin City)</i></li>
                <li>DizzyBird <i>(Twin City)</i></li>
                <li>AmethystStone <i>(Item)</i></li>
                <li>RubyStone <i>(Item)</i></li>
                <li>ForgeOrb <i>(Item)</i></li>
                <li>PetEgg <i>(Item)</i></li>
                <li>P8WeaponSoulPack <i>(Item)</i></li>
                <li>P8EquipmentSoulPack <i>(Item)</i></li>
                <li>YellowMan <i>(Garment)</i></li>
                <li>PinkyPurple <i>(Garment)</i></li>
                <li>SuperToken <i>(Item)</i></li>
            </ol>

            <p className="head">Walkthrough</p>
            <ol>
                <li>
                    <p className="instruction">Purchase the Battle Pass 10 through the Coin Store for 20 Coins.</p>
                    <Image src={Image1} fluid />
                </li>
                <li>
                    <p className="instruction">The Battle Pass 10 has 7 levels, you start from 0 and have to level up all the way up to 7. It takes 100 Battle Pass Points to level up.</p>
                    <Image src={Image2} fluid />
                    <p className="tip">*Note that the daily quests restart at 00:00 server time.</p>
                    <Image src={Image3} fluid />
                </li>
            </ol>
            <p className="head">About Daily Quests:</p>
            <li>There are 6 different quests, each one gives different amounts of Battle Pass Points and 2M CPs.
            </li>              
            <ol>          
                <ol>
                    <li>SacrificeToken: Highly recommend to use bow to farm them, it drops on the ground and you get a message box for it. Collect 10 and bring them to BlissBoy to swap them for 10 Battle Pass Points.</li>
                    <Image src={Image4} fluid />
                    <li>SpecialSpook: Defeat it to get 2M CPs and 10 Battle Pass points.</li>
                    <Image src={Image5} fluid />
                    <li>SpecialTerato: Defeat it to get 2M CPs and 10 Battle Pass points.</li>
                    <Image src={Image6} fluid />
                    <li>JinWoo: Defeat it to get 2M CPs and 10 Battle Pass points.</li>
                    <Image src={Image7} fluid />
                    <li>SpecialOctopus: Defeat it to get 2M CPs and 10 Battle Pass points.</li>
                    <Image src={Image8} fluid />
                    <li>SpecialHellhest: Defeat it to get 2M CPs and 10 Battle Pass points.</li>
                    <Image src={Image9} fluid />
                    <li>SpecialApe: Defeat it to get 2M CPs and 10 Battle Pass points.</li>
                    <Image src={Image10} fluid />

                    <li>Win Hourly Events: Upon winning any of the HOURLY events to obtain get 10 Battle Pass Points + 2M CPs.</li>
                    <li>Win 10 Arena Qualifier Matches: You must win 10 Arena Qualifier matches to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <li>Defeat Hourly Bosses(Includes Deityland and MegaTerato) to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <li>Complete Tower of Mystery to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <li>Attend the Infernal GW(Sign up through Balrog) to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <li>Attend the Elite GW(Sign up through GeneralRoo) to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <li>Attend the Elite Pk Tournament(Sign up through ElitePkEnvoy) to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <li>Win the Elite Pk/Skill Elite Pk Tournament to obtain 30 Battle Pass Points + 50M CPs.</li>

                </ol>
            </ol>
            <p className="head">About Levels:</p>
            <li>There are 7 levels, the reward are the following:
            </li>
            <ol>
                <ol>
                    <li>Level 1: 50M CPs, 5,000 Champion Points and SuperToken(Bound)</li>
                    <p className="tip">*Supertoken is a clickable item where you get to choose one of the new SUPER Cups for 30 days.</p>
                    <Image src={Image11} fluid />

                    <li>Level 2: 100M CPs.</li>
                    <li>Level 3: 100M CPs.</li>
                    <li>Level 4: 50M CPs and YellowMan(255 HP Bound Garment with 300 base stats)</li>
                    <Image src={Image12} fluid />

                    <li>Level 5: 100M CPs.</li>

                    <li>Level 6: 50M CPs and AmethystStone(Untradable)</li>
                    <p className="tip">*AmethystStone is a clickable item where you get to choose whether you want (3)PetEgg(Bound) or ForgeOrb(Bound)</p>
                    <Image src={Image13} fluid />
                    
                    <li>Level 7: 50M CPs, RubyStone(Untradable) and PinkyPurple(255 HP Bound Garment with 300 base stats)</li>
                    <p className="tip">*RubyStone is a clickable item where you get to choose whether you want P8WeaponSoulPack(Bound) or P8EquipmentSoulPack(Bound) Note that the P8 Items from these boxes will be BOUND.</p>
                    <Image src={Image14} fluid />
                    <Image src={Image15} fluid />
                </ol>
            </ol>
        </div>
    );
}