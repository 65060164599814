import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/BattlePass9/pic1.webp'
import Image2 from '../../images/Guide/BattlePass9/pic2.webp'
import Image3 from '../../images/Guide/BattlePass9/pic3.webp'
import Image4 from '../../images/Guide/BattlePass9/pic4.webp'
import Image5 from '../../images/Guide/BattlePass9/pic5.webp'
import Image6 from '../../images/Guide/BattlePass9/pic6.webp'
import Image7 from '../../images/Guide/BattlePass9/pic7.webp'
import Image8 from '../../images/Guide/BattlePass9/pic8.webp'
import Image9 from '../../images/Guide/BattlePass9/pic9.webp'
import Image10 from '../../images/Guide/BattlePass9/pic10.webp'
import Image11 from '../../images/Guide/BattlePass9/pic11.webp'
import Image12 from '../../images/Guide/BattlePass9/pic12.webp'
import Image13 from '../../images/Guide/BattlePass9/pic13.webp'
import Image14 from '../../images/Guide/BattlePass9/pic14.webp'
import Image15 from '../../images/Guide/BattlePass9/pic15.webp'
import Image16 from '../../images/Guide/BattlePass9/pic16.webp'
import Image17 from '../../images/Guide/BattlePass9/pic17.webp'
import Image18 from '../../images/Guide/BattlePass9/pic18.webp'
import { LinkContainer } from 'react-router-bootstrap';

export function BattlePass9() {
    return (
        <div className="guide white">
            <h3>Battle Pass</h3>
            
            <p className="head">About:</p>
            <ul>
                <li>This guide covers the Battle Pass #9.</li>
            </ul>

            <p className="head">NPCs, Items, Maps:</p>
            <ol>
                <li>BattlePass <i>(Item)</i></li>
                <li>FlowerStage <i>(Twin City)</i></li>
                <li>BlissBoy <i>(Twin City)</i></li>
                <li>PassPack1 <i>(Item)</i></li>
                <li>PassPack2 <i>(Item)</i></li>
                <li>ForgeOrb <i>(Item)</i></li>
                <li>UpgradeOrb <i>(Item)</i></li>
                <li>IchigoV2 <i>(Garment)</i></li>
                <li>DragonBlade <i>(Accessory)</i></li>
                <li>Kurome <i>(Garment)</i></li>
                <li>ApocalypseBlade <i>(Accessory)</i></li>
            </ol>

            <p className="head">Walkthrough</p>
            <ol>
                <li>
                    <p className="instruction">Purchase the Battle Pass 9 through the Coin Store for 20 Coins.</p>
                    <Image src={Image1} fluid />
                </li>
                <li>
                    <p className="instruction">The Battle Pass 9 has 7 levels, you start from 0 and have to level up all the way up to 7. It takes 100 Battle Pass Points to level up.</p>
                    <Image src={Image2} fluid />
                    <p className="tip">*Note that the daily quests restart at 00:00 server time.</p>
                    <Image src={Image3} fluid />
                </li>
            </ol>
            <p className="head">About Daily Quests:</p>
            <li>There are 6 different quests, each one gives different amounts of Battle Pass Points and 2M CPs.
            </li>              
            <ol>          
                <ol>
                    <li>SacrificeToken: Highly recommend to use bow to farm them, it drops on the ground and you get a message box for it. Collect 10 and bring them to BlissBoy to swap them for 20 Battle Pass Points.</li>
                    <Image src={Image4} fluid />
                    <li>City Tokens: Highly recommend to use bow to farm them, it drops on the ground and you get a message box for it. Collect all the 5 City Tokens from each city to swap them for 15 Battle Pass Points.</li>
                    <Image src={Image5} fluid />
                    <Image src={Image6} fluid />
                    <Image src={Image7} fluid />
                    <Image src={Image8} fluid />
                    <Image src={Image9} fluid />
                    <li>Goku: Your damage will be 1, this boss has 500 HP, once defeated you will obtain 10 Battle Pass Points.</li>
                    <Image src={Image10} fluid />

                    <li>Beast of Legend: Exterminate 1500 monsters inside this map to obtain 30 Battle Pass Points(Must be done as a Fire Taoist)</li>
                    <Image src={Image11} fluid />
                    <li>Win Hourly Events: Upon winning any of the HOURLY events, you will get 10 Battle Pass Points.</li>
                    <li>Defeat MegaTerato to get 15 Battle Pass Points.</li>
                </ol>
            </ol>
            <p className="head">About Levels:</p>
            <li>There are 7 levels, the reward are the following:
            </li>
            <ol>
                <ol>
                    <li>Level 1: 50M CPs, 5,000 Champion Points and (1) NameToken(Tradable)</li>
                    <li>Level 2: 50M CPs and 6,500 Champion Points.</li>
                    <li>Level 3: 50M CPs and 8,000 Champion Points.</li>
                    <li>Level 4: 50M CPs, 10,000 Champion Points and (1) IchigoV2(255 HP Bound Garment)</li>
                    <Image src={Image12} fluid />

                    <li>Level 5: 50M CPs, 12,000 Champion Points and (2) DragonBlade(Bound Accessory)</li>
                    <Image src={Image13} fluid />

                    <li>Level 6: 50M CPs, 15,000 Champion Points and (1) PassPack1(Untradable)</li>
                    <p className="tip">*PassPack1 is a clickable item where you get to choose whether you want ForgeOrb(Bound) or UpgradeOrb(Bound)</p>
                    <Image src={Image14} fluid />
                    <Image src={Image15} fluid />
                    <Image src={Image16} fluid />
                    
                    <li>Level 7: 50M CPs, (1) PassPack2(Untradable), Kurome(255 HP Bound Garment) and ApocalypseBlade(Bound Accessory)</li>
                    <p className="tip">*PassPack2 is a clickable item where you get to choose whether you want P8WeaponSoulPack(Bound) or P8EquipmentSoulPack(Tradable)</p>
                    <Image src={Image17} fluid />
                    <Image src={Image18} fluid />
                </ol>
            </ol>
        </div>
    );
}