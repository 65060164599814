import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/NewbieGuide/pic1.webp'
import Image2 from '../../images/Guide/NewbieGuide/pic2.webp'
import Image3 from '../../images/Guide/NewbieGuide/pic3.webp'
import Image4 from '../../images/Guide/NewbieGuide/pic4.webp'
import Image5 from '../../images/Guide/NewbieGuide/pic5.webp'
import Image6 from '../../images/Guide/NewbieGuide/pic6.webp'
import Image7 from '../../images/Guide/NewbieGuide/pic7.webp'
import Image8 from '../../images/Guide/NewbieGuide/pic8.webp'
import Image9 from '../../images/Guide/NewbieGuide/pic9.webp'
import Image10 from '../../images/Guide/NewbieGuide/pic10.webp'
import Image11 from '../../images/Guide/NewbieGuide/pic11.webp'
import Image12 from '../../images/Guide/NewbieGuide/pic12.webp'
import Image13 from '../../images/Guide/NewbieGuide/pic13.webp'
import Image14 from '../../images/Guide/NewbieGuide/pic14.webp'

export function NewbieGuide() {
    return (
        <div className="guide white">
            <h3>NewbieGuide</h3>
        
            <p className="head">Requirements to start the quest:</p>
            <ul>
                <li>Level 140 and 2nd reborn.</li>
            </ul>

            <p className="head">Rewards:</p>
            <ul>
                <li>462 Attribute Points.</li>
            </ul>

            <p className="head">Walkthrough</p>
            <ol>
                <li>
                    <p className="instruction">Talk to NewbieQuest (Twin City) to begin the quest.</p>
                    <Image src={Image1} fluid />
                </li>
                <li>
                    <p className="instruction">You will be teleported to Labyrinth 1 where you will have to hunt for SkyToken, once you have it you simply have to right click it to be teleported to the way out and advance to the next floor</p>
                    <p className="tip">*You may use the bow found in your inventory and Scatter skill to finish this quest quicker.</p>
                    <Image src={Image2} fluid />
                    <Image src={Image3} fluid />
                    <Image src={Image4} fluid />
                </li>
                <li>
                    <p className="instruction">You are sent to Labyrinth floor 2 where you will have to hunt for EarthToken, once you have it you simply have to right click it to be teleported to the way out and advance to the next floor.</p>
                    <Image src={Image5} fluid />
                    <Image src={Image6} fluid />
                    <Image src={Image7} fluid />
                </li>
                <li>
                    <p className="instruction">You are sent to Labyrinth floor 3 where you will have to hunt for SoulToken, once you have it you simply have to right click it to be teleported to the way out and advance to the next floor</p>
                    <Image src={Image8} fluid />
                    <Image src={Image9} fluid />
                    <Image src={Image10} fluid />
                </li>
                <li>
                    <p className="instruction">You are sent to Labyrinth floor 4 where you will have to kill 1000 monsters, when you complete it you will be sent to the way out to finish the quest and claim the reward.</p>
                    <Image src={Image11} fluid />
                    <Image src={Image12} fluid />
                    <Image src={Image13} fluid />
                    <Image src={Image14} fluid />
                </li>
            </ol>
        </div>
    );
}