import { useEffect } from 'react';
import {Nav, Col, Row, Dropdown } from 'react-bootstrap'
import { Switch, Route } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';

import { GuideSplash } from '../components/Guides/GuideSplash'
import { EpicTrojanQuest } from '../components/Guides/EpicTrojanQuest'
import { EpicNinjaQuest } from '../components/Guides/EpicNinjaQuest'
import { EpicMonkQuest } from '../components/Guides/EpicMonkQuest';
import { EpicWarriorQuest } from '../components/Guides/EpicWarriorQuest';
import { EpicTaoistQuest } from '../components/Guides/EpicTaoistQuest';
import { TempestWings } from '../components/Guides/TempestWings';
import { NewbieGuide } from '../components/Guides/NewbieGuide';
import { VipSystem } from '../components/Guides/VipSystem';
import { GettingStarted } from '../components/Guides/GettingStarted';
import { TreasureForest } from '../components/Guides/TreasureForest';
import { ChiSystem } from '../components/Guides/ChiSystem';
import { AttributePoints } from '../components/Guides/AttributePoints';
import { PerfectionSystem } from '../components/Guides/PerfectionSystem';
import { MiniCups } from '../components/Guides/MiniCups';
import { BattlePass1 } from '../components/Guides/BattlePass1';
import { BattlePass2 } from '../components/Guides/BattlePass2';
import { BattlePass3 } from '../components/Guides/BattlePass3';
import { BattlePass4 } from '../components/Guides/BattlePass4';
import { BattlePass5 } from '../components/Guides/BattlePass5';
import { BattlePass6 } from '../components/Guides/BattlePass6';
import { BattlePass7 } from '../components/Guides/BattlePass7';
import { BattlePass9 } from '../components/Guides/BattlePass9';
import { BattlePass10 } from '../components/Guides/BattlePass10';
import { GameCommands } from '../components/Guides/GameCommands';

function Guide() {
  useEffect(() => {
    let sidebar = document.getElementById('sidebarColumn');
    let main = document.getElementById('contentColumn');

    sidebar.classList.add('hide');
    main.classList.remove('col-lg-8');
    main.classList.add('col-lg-10');

    return () => {
      sidebar.classList.remove('hide');
      main.classList.add('col-lg-8');
      main.classList.remove('col-lg-10');
    }
  }, []);

  return (
      <div>
        <div className="white guide-header center">
          <h2>Paragon Conquer Guide</h2>
        </div>
        <div className="guide-page">
          <Row>
            <Col xs={12} className="guide-nav">
              <Nav className="justify-content-center" activeKey="/home">
                <Nav.Item>
                  <LinkContainer to="/guide/newbies/NewbieGuide">
                    <Nav.Link>Newbies</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
                <Dropdown as={Nav.Item}>
                  <Dropdown.Toggle as={Nav.Link}>Gameplay</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <LinkContainer to="/guide/gameplay/GettingStarted">
                      <Dropdown.Item>Getting Started</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/guide/gameplay/GameCommands">
                      <Dropdown.Item>Game Commands</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/guide/gameplay/PerfectionSystem">
                      <Dropdown.Item>Perfection System</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/guide/gameplay/VipSystem">
                      <Dropdown.Item>Vip System</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/guide/gameplay/TreasureForest">
                      <Dropdown.Item>Treasure Forest</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/guide/gameplay/ChiSystem">
                      <Dropdown.Item>Chi System</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/guide/gameplay/AttributePoints">
                      <Dropdown.Item>Attribute Points</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/guide/gameplay/MiniCups">
                      <Dropdown.Item>Mini Cups</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="hidden" to="/guide/gameplay/BattlePass1">
                      <Dropdown.Item>Battle Pass</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="hidden" to="/guide/gameplay/BattlePass2">
                      <Dropdown.Item>Battle Pass 2</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="hidden" to="/guide/gameplay/BattlePass3">
                      <Dropdown.Item>Battle Pass 3</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="hidden" to="/guide/gameplay/BattlePass4">
                      <Dropdown.Item>Battle Pass</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="hidden" to="/guide/gameplay/BattlePass5">
                      <Dropdown.Item>Battle Pass</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="hidden" to="/guide/gameplay/BattlePass6">
                      <Dropdown.Item>Battle Pass</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="hidden" to="/guide/gameplay/BattlePass7">
                      <Dropdown.Item>Battle Pass</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="hidden" to="/guide/gameplay/BattlePass9">
                      <Dropdown.Item>Battle Pass</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/guide/gameplay/BattlePass10">
                      <Dropdown.Item>Battle Pass</Dropdown.Item>
                    </LinkContainer>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown as={Nav.Item}>
                  <Dropdown.Toggle as={Nav.Link}>Epic Quests</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <LinkContainer to="/guide/quests/EpicTrojanQuest">
                      <Dropdown.Item>Epic Trojan Quest</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/guide/quests/EpicWarriorQuest">
                      <Dropdown.Item>Epic Warrior Quest</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/guide/quests/EpicTaoistQuest">
                      <Dropdown.Item>Epic Taoist Quest</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/guide/quests/EpicNinjaQuest">
                      <Dropdown.Item>Epic Ninja Quest</Dropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/guide/quests/EpicMonkQuest">
                      <Dropdown.Item>Epic Monk Quest</Dropdown.Item>
                    </LinkContainer>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Col>
            <Col>
              <Switch>
                <Route path="/guide/newbies/NewbieGuide" component={NewbieGuide} />
                
                <Route path="/guide/gameplay/TempestWings" component={TempestWings} />
                <Route path="/guide/gameplay/GettingStarted" component={GettingStarted} />
                <Route path="/guide/gameplay/PerfectionSystem" component={PerfectionSystem} />
                <Route path="/guide/gameplay/VipSystem" component={VipSystem} />
                <Route path="/guide/gameplay/TreasureForest" component={TreasureForest} />
                <Route path="/guide/gameplay/ChiSystem" component={ChiSystem} />
                <Route path="/guide/gameplay/AttributePoints" component={AttributePoints} />
                <Route path="/guide/gameplay/MiniCups" component={MiniCups} />
                <Route path="/guide/gameplay/BattlePass1" component={BattlePass1} />
                <Route path="/guide/gameplay/BattlePass2" component={BattlePass2} />
                <Route path="/guide/gameplay/BattlePass3" component={BattlePass3} />
                <Route path="/guide/gameplay/BattlePass4" component={BattlePass4} />
                <Route path="/guide/gameplay/BattlePass5" component={BattlePass5} />
                <Route path="/guide/gameplay/BattlePass6" component={BattlePass6} />
                <Route path="/guide/gameplay/BattlePass7" component={BattlePass7} />
                <Route path="/guide/gameplay/BattlePass9" component={BattlePass9} />
                <Route path="/guide/gameplay/BattlePass10" component={BattlePass10} />
                <Route path="/guide/gameplay/GameCommands" component={GameCommands} />

                <Route path="/guide/quests/EpicTrojanQuest" component={EpicTrojanQuest} />
                <Route path="/guide/quests/EpicWarriorQuest" component={EpicWarriorQuest} />
                <Route path="/guide/quests/EpicTaoistQuest" component={EpicTaoistQuest} />
                <Route path="/guide/quests/EpicNinjaQuest" component={EpicNinjaQuest} />
                <Route path="/guide/quests/EpicMonkQuest" component={EpicMonkQuest} />
				
                <Route path="/guide/" component={GuideSplash}/>
              </Switch>
            </Col>
          </Row>
        </div>
      </div>
  );
}

export { Guide };